.list {
  padding: 0px;
}

.MuiList-padding {
  padding: 0px;
}

.paper-root .MuiList-padding {
  max-height: none;
  padding: 0px;
  height: 85vh;
}

.root {
  max-height: none;
}

.desktop a {
  color: #f7f0d7;
}
